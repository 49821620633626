<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="show"
    append-to-body
    width="800px"
    v-loading="loading"
  >
    <el-table border :data="errorTableList" v-loading="loading" height="200px">
      <el-table-column
        header-align="center"
        width="80"
        prop="index"
        label="失败行数"
      />
      <el-table-column
        header-align="center"
        show-overflow-tooltip
        prop="message"
        label="失败原因"
      />
    </el-table>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: {
	  title: {
	  	default: '导入文件信息',
	  	type: String
	  },
	  errorTableList: {} ,
  },
  data() {
    return {
      loading: false,
    }
  }
}
</script>
<style scoped lang="less">
.tip {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  span {
    margin: 0 4px;
  }
}
</style>
