//领料申请(销库)
<template>
  <Page ref="page" v-loading="loading" :showReturn="!isCreate">
    <div>
      <el-form ref="form" label-width="140px" :model="params" :rules="rules">
        <div class="cm-flex-line">
          <el-form-item label="申请人：" prop="proposerCode">
            <SelectProposer
              :code.sync="params.proposerCode"
              :name.sync="params.proposerName"
              @change="proposerChange"
              :disabled="!isCreate"
            />
          </el-form-item>
          <el-form-item label="申请部门：" prop="applyDepartmentCode">
            <SelectDepartment
              ref="SelectDepartment"
              :code.sync="params.applyDepartmentCode"
              :name.sync="params.applyDepartmentName"
              disabled
            />
          </el-form-item>
        </div>
        <div class="cm-flex-line">
          <el-form-item label="申请日期：" prop="applyTime">
            <el-date-picker
              style="width: 100%"
              v-model="params.applyTime"
              placeholder="请选择日期"
              value-format="yyyy-MM-dd"
              :picker-options="{
                disabledDate(time) {
                  return time.getTime() > Date.now()
                }
              }"
            />
          </el-form-item>
          <el-form-item label="出库仓库：" prop="outWarehouseCode">
            <SelectWarehouse
              :code.sync="params.outWarehouseCode"
              :name.sync="params.outWarehouseName"
              @change="changeWarehouse"
            />
          </el-form-item>
        </div>
        <el-form-item label="申请理由：" prop="reason">
          <el-input
            v-model="params.reason"
            placeholder="请输入申请理由"
            maxlength="200"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input
            v-model="params.remark"
            placeholder="请输入备注"
            type="textarea"
            maxlength="255"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="fileList" label="附件：">
          <UploadFile
            ref="UploadFile"
            v-model="params.fileList"
            :showAccept="false"
            :limit="3"
            multiple
            :preAppendixAddr="params && params.attachmentUrl"
          />
        </el-form-item>
        <el-divider content-position="left">申请物品清单</el-divider>
        <div style="display: flex; justify-content: end; margin-bottom: 10px">
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="addEvent()"
          >
            添加
          </el-button>
        </div>
        <el-table :data="params.detailUpdateInputs">
          <el-table-column header-align="center" label="材料料号">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.code'"
                :rules="rules.code"
              >
                <SelectGoodsCode
                  :code.sync="params.detailUpdateInputs[$index].code"
                  :name.sync="params.detailUpdateInputs[$index].name"
                  :bserial.sync="params.detailUpdateInputs[$index].bserial"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="材料名称">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.name'"
                :rules="rules.name"
              >
                <SelectGoodsName
                  :code.sync="params.detailUpdateInputs[$index].code"
                  :name.sync="params.detailUpdateInputs[$index].name"
                  :bserial.sync="params.detailUpdateInputs[$index].bserial"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="出库货位">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.outPositionCode'"
                :rules="rules.outPositionCode"
              >
                <SelectGoodsLocation
                  :ref="'SelectGoodsLocationOut' + $index"
                  :code.sync="params.detailUpdateInputs[$index].outPositionCode"
                  :name.sync="params.detailUpdateInputs[$index].outPositionName"
                  :maxNum.sync="params.detailUpdateInputs[$index].maxOutNum"
                  :params="{
                    inventoryCode: params.detailUpdateInputs[$index].code,
                    warehouseCode: params.outWarehouseCode
                  }"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="存货数量">
            <template slot-scope="{ row, $index }">
              <el-form-item label="" label-width="0">
                <el-input
                  v-model="params.detailUpdateInputs[$index].maxOutNum"
                  readonly
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="数量">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.num'"
                :rules="rules.num"
              >
                <el-input
                  v-model="params.detailUpdateInputs[$index].num"
                  placeholder="请输入数量"
                  @input="
                    val =>
                      (params.detailUpdateInputs[$index].num =
                        $format.fmtNumber(val))
                  "
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="sn">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.sns'"
              >
                <SelectSn
                  :ref="'SelectSn' + $index"
                  :code.sync="params.detailUpdateInputs[$index].sns"
                  inputDisabled
                  multiple
                  :params="{
                    cInvCode: params.detailUpdateInputs[$index].code,
                    cWhCode: params.outWarehouseCode,
                    cPosCode: params.detailUpdateInputs[$index].outPositionCode,
                    bserial: params.detailUpdateInputs[$index].bserial
                  }"
                  @change="
                    v => (params.detailUpdateInputs[$index].num = v.length)
                  "
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="备注">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.remark'"
              >
                <el-input
                  v-model="params.detailUpdateInputs[$index].remark"
                  placeholder="请输入备注"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label=" " width="60">
            <template slot-scope="{ row, $index }">
              <el-form-item label="" label-width="0">
                <el-button type="text" @click="deleteEvent($index)">
                  删除
                </el-button>
              </el-form-item>
            </template>
          </el-table-column>
          <template slot="empty">
            <div>暂无数据，请添加</div>
          </template>
        </el-table>
      </el-form>
    </div>
    <FlowableStep
      orderType="7"
      :variables="variables"
      @initNode="setNodes"
      @refresh="setAuditors"
    ></FlowableStep>
    <template slot="bottom">
      <el-button
        type="primary"
        @click="submitEvent(1)"
        style="margin-right: 10px"
      >
        提交申请
      </el-button>
      <el-button type="info" @click="submitEvent(2)">保存草稿</el-button>
    </template>
    <ImportErrorInfo
      v-if="importErrorInfoVisible"
      title="库存校验失败"
      :visible.sync="importErrorInfoVisible"
      :errorTableList="errorTableList"
    />
  </Page>
</template>

<script>
import FlowableStep from '@/components/FlowableStep'
import ImportErrorInfo from '@/components/ImportErrorInfo'
import applyMixins from '../../mixins/applyMixins'
export default {
  mixins: [applyMixins],
  components: { FlowableStep, ImportErrorInfo },
  data() {
    return {
      rules: {
        proposerCode: [{ required: true, message: '请选择申请人' }],
        applyDepartmentCode: [{ required: true, message: '请选择申请人部门' }],
        applyTime: [{ required: true, message: '请选择日期' }],
        outWarehouseCode: [{ required: true, message: '请选择出库仓库' }],
        reason: [{ required: true, message: '请输入申请理由' }],
        num: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let index = rule.field?.split('.')[1]
              let { maxOutNum, outPositionCode } =
                this.params.detailUpdateInputs[index] || {}
              if (!value || Number(value) === 0) {
                callback('请输入非0整数')
              } else if (outPositionCode && Number(value) > Number(maxOutNum)) {
                callback('不能大于存货数量')
              } else {
                callback()
              }
            }
          }
        ],
        code: [{ required: true, message: '请选择' }],
        name: [{ required: true, message: '请选择' }]
      },
      params: {
        outWarehouseCode: '001',
        outWarehouseName: '服务支撑仓',
        detailUpdateInputs: [],
        auditors: {}
      },
      nodes: [],
      variables: {},
      importErrorInfoVisible: false,
      errorTableList: []
    }
  },
  computed: {
    isCreate() {
      return this.$route.query.edit != 1
    }
  },
  mounted() {
    this.loading = true
    this.init()
    this.loading = false
  },
  methods: {
    setNodes(nodes) {
      this.nodes = nodes
    },
    setAuditors(auditors) {
      this.params.auditors = auditors
    },
    async init() {
      try {
        const { orderCode } = this.$route.query
        const res = await this.$api.apply.pickingOrderInfo({ orderCode })
        if (res) {
          let { orderResult = {}, detailResults = [] } = res
          this.params = {
            ...this.params,
            ...orderResult,
            detailUpdateInputs: detailResults
          }
        }
        if (!this.params.outWarehouseCode) {
          this.$set(this.params, 'outWarehouseCode', '001')
          this.$set(this.params, 'outWarehouseName', '服务支撑仓')
        }
      } catch (err) {
        console.log(err)
      }
    },
    //申请人改变
    proposerChange(a) {
      this.$nextTick(() => {
        this.$refs.SelectDepartment?.changeEvent(a.cdepCode)
      })
    },
    changeWarehouse() {
      this.params.detailUpdateInputs = this.params.detailUpdateInputs.map(
        item => {
          item.outPositionCode == ''
          item.outPositionName = ''
          return item
        }
      )
    },
    addEvent() {
      this.params.detailUpdateInputs.push({
        code: '',
        name: '',
        num: '',
        sns: '',
        outPositionCode: '',
        outPositionName: '',
        remark: ''
      })
    },
    deleteEvent(index) {
      this.params.detailUpdateInputs = this.params.detailUpdateInputs.filter(
        (a, i) => i !== index
      )
    },
    submitEvent(submit) {
      if (this.loading) return
      let params = this.$utils.deepClone(this.params)
      if (params.applyTime) {
        params.applyTime = params.applyTime + ' 00:00:00'
      }
      if (params.fileList?.length > 0) {
        params.attachmentUrl = params.fileList
          .map(a => {
            return `${a.url}?fileName=${a.name}`
          })
          .join(',')
        delete params.fileList
      }
      let detailUpdateInputs = params.detailUpdateInputs
      delete params.detailUpdateInputs
      let newParams = {
        submit: submit == 1,
        detailUpdateInputs,
        updateInput: params
      }
      if (submit != 1) {
        try {
          this.loading = true
          this.$api.apply.pickingOrderSave(newParams).then(result => {
            this.$message.success('草稿保存成功')
          })
        } finally {
          this.loading = false
        }
      } else {
        if (this.nodes.length) {
          let flag = true
          for (let i = 0; i < this.nodes.length; i++) {
            if (
              this.nodes[i].assigneeType == 'SELF_SELECT' &&
              this.nodes[i].userType == 2 &&
              this.nodes[i].users.length == 0 &&
              !this.params?.auditors[this.nodes[i].id]?.length
            ) {
              flag = false
              return this.$message.warning('请选择审批人后提交')
            }
          }
          if (!flag) return
        }
        this.$refs['form'].validate(async valid => {
          if (valid) {
            try {
              if (this.params.detailUpdateInputs.length === 0) {
                this.$message.warning('请至少添加一条材料清单')
                return
              }
              this.loading = true
              // let checkInputs = newParams.detailUpdateInputs.map(item => {
              //   return {
              //     code: item.code,
              //     num: item.num
              //   }
              // })
              // let errors = await this.$api.apply.checkNumBeforeSubmit({
              //   warehouseCode: newParams.updateInput.outWarehouseCode,
              //   checkInputs
              // })
              // if (errors && errors.length) {
              //   this.importErrorInfoVisible = true
              //   this.errorTableList = errors.map((message, index) => {
              //     return {
              //       index: index + 1,
              //       message: message
              //     }
              //   })
              //   return
              // }
              clearInterval(this.timeSaveFlag)
              await this.$api.apply.pickingOrderSave(newParams)
              //提交成功
              this.submitSuccess('/#/pickingOrderApply')
            } finally {
              this.loading = false
            }
          }
        })
      }
    }
  }
}
</script>
